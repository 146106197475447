import React from 'react';
import s from './Filter.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {FilterType} from '../../types/types';
import {IColorFilterProps} from '../ColorFilter/ColorFilter';
import {Minus, Plus} from '../../icons/dist';

export enum DataHook {
  Title = 'title',
  OptionName = 'option-name',
  ExpandIcon = 'expand-icon',
  TitleWrapper = 'title-wrapper',
}

export interface IFilterProps extends IProvidedGlobalProps {
  title: string;
  filterType: FilterType;
  filterIndex: number;
  children?: React.ReactChild;
}

export interface IFilterState {
  expanded: boolean;
  optionName?: string;
}

@withGlobalProps
export class Filter extends React.Component<IFilterProps, IFilterState> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.filterIndex === 0,
      optionName: '',
    };
  }

  public render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      const props: Partial<IColorFilterProps> = {};

      if (this.props.filterType === FilterType.COLOR_OPTION) {
        props.showOptionName = this.showOptionName.bind(this);
      }

      return React.cloneElement(child as React.ReactElement<any>, props);
    });
    return (
      <>
        <button
          onClick={() => this.toggleExpand()}
          className={s.header}
          data-hook={DataHook.TitleWrapper}
          aria-expanded={this.state.expanded}>
          <span className={s.title}>
            <span data-hook={DataHook.Title}>{this.props.title}</span>
            {this.state.optionName && <span data-hook={DataHook.OptionName}>: {this.state.optionName}</span>}
          </span>
          <span data-hook={DataHook.ExpandIcon} className={s.expandIcon}>
            {this.state.expanded ? <Minus /> : <Plus />}
          </span>
        </button>
        {this.state.expanded && <div className={s.content}>{childrenWithProps}</div>}
      </>
    );
  }

  private toggleExpand() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  private showOptionName(name: string) {
    this.setState({optionName: name});
  }
}
