import React from 'react';
import {IGalleryStyleParams, IPropsInjectedByViewerScript, IGallerySantaProps} from '../../types/types';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: Pick<
    IPropsInjectedByViewerScript & IGallerySantaProps,
    | 'appLoadBI'
    | 'dimensions'
    | 'filterModels'
    | 'filterProducts'
    | 'focusedProductIndex'
    | 'handleProductItemClick'
    | 'hasMoreProductsToLoad'
    | 'isFirstPage'
    | 'isInteractive'
    | 'isLiveSiteMode'
    | 'isMobile'
    | 'loadMoreProducts'
    | 'mainCollectionId'
    | 'openQuickView'
    | 'productIdToProductPageUrlMap'
    | 'products'
    | 'setProductsPerPage'
    | 'sortProducts'
    | 'textsMap'
    | 'totalProducts'
  > & {styleParams: IGalleryStyleParams};
}

export function withGlobalPropsProvider(Component) {
  //todo:eran: function instead of class
  return class GlobalPropsProvider extends React.PureComponent<IPropsInjectedByViewerScript & IGallerySantaProps> {
    public render() {
      const {
        appLoadBI,
        dimensions,
        filterModels,
        filterProducts,
        focusedProductIndex,
        handleProductItemClick,
        hasMoreProductsToLoad,
        isFirstPage,
        isInteractive,
        isLiveSiteMode,
        isMobile,
        loadMoreProducts,
        mainCollectionId,
        openQuickView,
        productIdToProductPageUrlMap,
        products,
        setProductsPerPage,
        sortProducts,
        style,
        textsMap,
        totalProducts,
        ...passThroughProps
      } = this.props;

      return (
        <PropsContext.Provider
          value={{
            globals: {
              loadMoreProducts,
              sortProducts,
              products,
              focusedProductIndex,
              isFirstPage,
              filterModels,
              isInteractive,
              hasMoreProductsToLoad,
              mainCollectionId,
              filterProducts,
              isMobile,
              styleParams: style.styleParams,
              openQuickView,
              dimensions,
              totalProducts,
              setProductsPerPage,
              handleProductItemClick,
              isLiveSiteMode,
              productIdToProductPageUrlMap,
              textsMap,
              appLoadBI,
            },
          }}>
          <Component {...passThroughProps} />
        </PropsContext.Provider>
      );
    }
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => (
    <PropsContext.Consumer>
      {globalProps => <Component {...globalProps} {...props} ref={props.innerRef} />}
    </PropsContext.Consumer>
  );
}
