import {IWixStyleParams} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {IControllerConfig, IStyle} from '@wix/native-components-infra/dist/src/types/types';
import {ISantaProps} from '@wix/wixstores-client-core/dist/es/src/types/native-types';
import {GalleryStore} from '../viewerScript/galleryStore';
import {GetDataQuery} from '../graphql/queries-schema';
import {IFilterValue} from '@wix/wixstores-graphql-schema';
import {CollectionFilterModel} from '../models/collectionFilterModel';
import {CustomCollectionFilterModel} from '../models/customCollectionFilterModel';
import {ListFilterModel} from '../models/listFilterModel';
import {ColorFilterModel} from '../models/colorFilterModel';
import {PriceFilterModel} from '../models/priceFilterModel';

export interface IGalleryStyleParams extends IWixStyleParams {
  booleans: Partial<{
    gallery_showPrice: boolean;
    full_width: boolean;
    galleryShowSort: boolean;
    galleryShowFilters: boolean;
    gallery_showProductName: boolean;
    gallery_showDividers: boolean;
    gallerySortNameAsc: boolean;
    gallerySortNameDes: boolean;
    gallerySortNewest: boolean;
    gallerySortPriceAsc: boolean;
    gallerySortPriceDes: boolean;
    showQuickView: boolean;
    showAlternativeImage: boolean;
    galleryFiltersCategories: boolean;
    galleryFiltersPrice: boolean;
    galleryFiltersProductOptions: boolean;
  }>;
  numbers: Partial<{
    galleryColumns: number;
    galleryRows: number;
    galleryMargin: number;
    gallery_imageMode: ImageModeId;
    gallery_productMargin: number;
    galleryImageRatio: ImageRatioId;
    ['mobile:galleryColumns']: number;
    ['mobile:galleryMargin']: number;
  }>;
  fonts?: Partial<{
    gallery_hoverType: {fontStyleParam: boolean; value: HoverType};
  }>;
}

export interface IGalleryControllerConfig extends IControllerConfig {
  style: {
    styleParams: IGalleryStyleParams;
  };
}

export interface IGalleryStyle extends IStyle {
  styleParams: IGalleryStyleParams;
}

export interface IGallerySantaProps extends ISantaProps {
  style: IGalleryStyle;
}

export interface IPriceRangeValue {
  min: string;
  max: string;
}

export interface IRangeValue {
  min: number;
  max: number;
}

export interface ITextsMap {
  quickViewButtonText: string;
  filtersTitleText: string;
  loadMoreButtonText: string;
  clearFiltersButtonText: string;
  allCollectionsFilterButtonText: string;
  noProductsMessageText: string;
  noProductsFilteredMessageText: string;
  productOutOfStockText: string;
  sortTitleText: string;
  sortOptionNewestText: string;
  sortOptionLowPriceText: string;
  sortOptionHighPriceText: string;
  sortOptionNameAZText: string;
  sortOptionNameZAText: string;

  productPriceBeforeDiscountSR: string;
  productPriceAfterDiscountSR: string;
  productPriceWhenThereIsNoDiscountSR: string;
  galleryRegionSR: string;
}

export interface IPropsInjectedByViewerScript {
  clearFilters: Function;
  cssBaseUrl: string;
  filterModels: FilterModel[];
  filterProducts(filterId: number, value: IFilterSelectionValue): void;
  focusedProductIndex?: number;
  handleProductItemClick: GalleryStore['handleProductItemClick'];
  hasMoreProductsToLoad: boolean;
  isFirstPage: boolean;
  isInteractive: boolean;
  isLiveSiteMode: boolean;
  isLoaded: boolean;
  isMobile: boolean;
  isRTL: boolean;
  loadMoreProducts: Function;
  mainCollectionId: string;
  onAppLoaded: Function;
  openQuickView: GalleryStore['openQuickView'];
  productIdToProductPageUrlMap: {[key: string]: string};
  products: IProduct[];
  ravenUserContextOverrides: {id: string};
  setProductsPerPage: Function;
  shouldShowClearFilters: boolean;
  hasSelectedFilters: boolean;
  shouldShowSort: boolean;
  sortProducts: Function;
  textsMap: ITextsMap;
  totalProducts: number;
}

export type IProduct = GetDataQuery['catalog']['category']['productsWithMetaData']['list'][0];

export enum ProductType {
  DIGITAL = 'digital',
  PHYSICAL = 'physical',
}

export enum MediaFrameMediaType {
  SECURE_PICTURE = 'secure_picture',
  SECURE_VIDEO = 'secure_video',
  SECURE_DOCUMENT = 'secure_document',
  SECURE_MUSIC = 'secure_music',
  SECURE_ARCHIVE = 'secure_archive',
}

export interface IProductItemData {
  id: string;
  name: string;
  urlPart: string;
  price: number;
  comparePrice: number;
  formattedPrice: string;
  formattedComparePrice: string;
  ribbon: string;
  isOptionMandatory: boolean;
  media: IMediaData[];
  inventoryStatus: any;
  isInStock?: boolean;
  productType: ProductType;
  digitalProductFilesTypes: MediaFrameMediaType[];
}

export interface IMediaData extends IImageDimension {
  url: string;
  mediaType: string;
  index: number;
  title: string;
  altText: string;
}

export interface IImageDimension {
  width: number;
  height: number;
}

export type IFilterSelectionValue = string | IPriceRangeValue;

export interface IFilterModel {
  filterType: FilterType;
  title: string;
  filterId: number;
  toDTO?: Function;
  update(value: IFilterSelectionValue): void;
  hasSelectedOptions?(): boolean;
  reset(): void;
}

export type IFilterOption = IFilterValue;

export interface IFilterConfig {
  filterTitle: string;
  filterType: FilterConfigType;
  show: boolean;
  custom?: boolean;
  selected?: {[id: string]: string}[];
  id?: string;
}

export enum FilterType {
  COLLECTION = 'COLLECTION',
  CUSTOM_COLLECTION = 'CUSTOM_COLLECTION',
  PRICE = 'PRICE ',
  COLOR_OPTION = 'COLOR_OPTION',
  LIST_OPTION = 'LIST_OPTION',
}

export enum FilterTypeFromFetch {
  CATEGORY = 'CATEGORY',
  FILTERED_CATEGORIES = 'FILTERED_CATEGORIES',
  PRICE = 'PRICE',
  COLOR = 'OPTION_COLOR',
  LIST = 'OPTION_LIST',
}

export enum FilterConfigType {
  CATEGORY = 'CATEGORY',
  PRICE = 'PRICE',
  OPTIONS = 'OPTIONS',
  OPTION_LIST = 'OPTION_LIST',
}

export type FilterModel =
  | CollectionFilterModel
  | CustomCollectionFilterModel
  | ListFilterModel
  | ColorFilterModel
  | PriceFilterModel;

export interface IFilterDTO {
  field: string;
  op: FilterEqOperation;
  values: any[];
}

export interface ICollectionIdsFilterDTO {
  mainCategory: string;
  subCategory?: string;
  customCategories?: string[][];
}

export enum FilterTypeForFetch {
  CATEGORY = 'CATEGORY',
  FILTERED_CATEGORIES = 'FILTERED_CATEGORIES',
  OPTIONS = 'OPTIONS',
  PRICE = 'PRICE',
}

export interface IDeprecatedFilterConfigDTO {
  filterTitle: string;
  filterType: FilterConfigType;
  show: boolean;
  custom?: boolean;
  selected?: {[id: string]: string}[];
}

export interface IFilterConfigDTO {
  filterType: FilterConfigType;
  id: string;
  show: boolean;
  custom?: boolean;
  selected?: {[id: string]: string}[];
}

export enum FilterEqOperation {
  EQUALS = 'EQUALS',
  GTE = 'GTE', // Greater than or equal to
  LTE = 'LTE', // Less than or equal to
  BETWEEN = 'BETWEEN',
  IN = 'IN',
  IN_ALL = 'IN_ALL',
}

export interface IFilteredCategoryProductsViewResponse {
  data: {
    products: IProductItemData[];
    totalCount: number;
  };
  errors?: any[];
}

export type nonCollectionFilterModel = ListFilterModel | ColorFilterModel | PriceFilterModel;

export interface IColorOption {
  rgbValue: string;
  name: string;
}

export enum ImageModeId {
  Crop = 1,
  Fit = 2,
}

export enum HoverType {
  None = 'none',
  Zoom = 'zoom',
  Border = 'border',
  Alternate = 'alternate',
}

export enum ImageRatioId {
  _3x2 = 0,
  _4x3 = 1,
  _1x1 = 2,
  _3x4 = 3,
  _2x3 = 4,
  _16x9 = 5,
  _9x16 = 6,
}

export interface ISorting extends ISortingParam {
  id: string;
  titleKey: string;
}

export const enum SortingDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface IAmountLimit {
  from: number;
  to: number;
}

export interface ISortingParam {
  field: string;
  direction?: SortingDirection;
}

export interface IFilteredProductsRequestParams {
  filters: IFilterDTO[];
  limit: IAmountLimit;
  sort?: ISortingParam;
  categories?: ICollectionIdsFilterDTO;
}

export interface IFilteredProductsRequest {
  viewName: string;
  params: IFilteredProductsRequestParams;
}

export interface IWarmupData {
  translations?: any;
  data?: any;
}
